const BREAKPOINTS = [0, 768, 992, 1200];
const NEW_BREAKPOINTS = [0, 476, 760, 1024, 1280];
function getBreakpointIndex(width, breakpoints) {
    return breakpoints.findIndex((_, index) => {
        return isBreakpointIndex(width, index, breakpoints);
    });
}
function isBreakpointIndex(width, index, breakpoints) {
    if (index === breakpoints.length - 1) {
        return width >= breakpoints[index];
    }
    return width >= breakpoints[index] && width < breakpoints[index + 1];
}
/**
 * Compares two breakpoints.
 * @param firstMq The first breakpoint to compare
 * @param secondMq The second breakpoint to compare
 * @returns -1, 1 or 0, whether the first breakpoint comes before after or is the same as the second breakpoint.
 */
export function compareBreakpoints(firstMq, secondMq) {
    return firstMq.localeCompare(secondMq, "de-DE");
}
/**
 * @internal
 */
class InternalBreakpointAware {
    constructor(onSizeChange, useNewBreakpoints = false) {
        this.onSizeChange = onSizeChange;
        this.internalCallback = () => {
            const newBreakpoint = getBreakpointIndex(window.innerWidth, this.breakpoints);
            if (this.currentBreakpoint !== newBreakpoint) {
                this.currentBreakpoint = newBreakpoint;
                this.onSizeChange();
            }
        };
        window.addEventListener("resize", this.internalCallback);
        this.breakpoints = useNewBreakpoints ? NEW_BREAKPOINTS : BREAKPOINTS;
        this.currentBreakpoint = getBreakpointIndex(window.innerWidth, this.breakpoints);
    }
    /**
     * Function which returns a value dependent on the current breakpoint.
     * @param mediaQueryValues An object containing optional values for every breakpoint.
     * @returns Returns the value defined for the breakpoint defined in mediaQueryValues. If no value is defined for a specific breakpoint the value of the previous breakpoint will be returned instead.
     */
    getEffectiveValue(mediaQueryValues) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        if (this.mq1) {
            return mediaQueryValues.mq1;
        }
        if (this.mq2) {
            return (_a = mediaQueryValues.mq2) !== null && _a !== void 0 ? _a : mediaQueryValues.mq1;
        }
        if (this.mq3) {
            return (_c = (_b = mediaQueryValues.mq3) !== null && _b !== void 0 ? _b : mediaQueryValues.mq2) !== null && _c !== void 0 ? _c : mediaQueryValues.mq1;
        }
        if (this.mq4) {
            return ((_f = (_e = (_d = mediaQueryValues.mq4) !== null && _d !== void 0 ? _d : mediaQueryValues.mq3) !== null && _e !== void 0 ? _e : mediaQueryValues.mq2) !== null && _f !== void 0 ? _f : mediaQueryValues.mq1);
        }
        if (this.mq5) {
            return ((_k = (_j = (_h = (_g = mediaQueryValues.mq5) !== null && _g !== void 0 ? _g : mediaQueryValues.mq4) !== null && _h !== void 0 ? _h : mediaQueryValues.mq3) !== null && _j !== void 0 ? _j : mediaQueryValues.mq2) !== null && _k !== void 0 ? _k : mediaQueryValues.mq1);
        }
        throw new Error("Illegal state! Cannot find breakpoint");
    }
    getCurrentBreakpoint() {
        return this.getEffectiveValue({
            mq1: "mq1",
            mq2: "mq2",
            mq3: "mq3",
            mq4: "mq4",
            mq5: "mq5",
        });
    }
    /**
     * A function to check if the viewport is currently on the "MQ1" breakpoint.
     * @returns Returns true when the current breakpoint is "MQ1" or false when not.
     */
    get mq1() {
        return this.currentBreakpoint === 0;
    }
    /**
     * A function to check if the viewport is currently on the "MQ2" breakpoint.
     * @returns Returns true when the current breakpoint is "MQ2" or false when not.
     */
    get mq2() {
        return this.currentBreakpoint === 1;
    }
    /**
     * A function to check if the viewport is currently on the "MQ3" breakpoint.
     * @returns Returns true when the current breakpoint is "MQ3" or false when not.
     */
    get mq3() {
        return this.currentBreakpoint === 2;
    }
    /**
     * A function to check if the viewport is currently on the "MQ4" breakpoint.
     * @returns Returns true when the current breakpoint is "MQ4" or false when not.
     */
    get mq4() {
        return this.currentBreakpoint === 3;
    }
    /**
     * A function to check if the viewport is currently on the "MQ5" breakpoint.
     * @returns Returns true when the current breakpoint is "MQ5" or false when not.
     */
    get mq5() {
        return this.currentBreakpoint === 4;
    }
    /**
     * Disconnects the callback from the window resize handler.
     * This function should be called no later than the destruction of the object holding a reference to the BreakpointAware object.
     */
    disconnect() {
        window.removeEventListener("resize", this.internalCallback);
    }
}
/**
 * Utility class which consumes a callback which is called as soon as the viewport hits a new breakpoint.
 * This class is only used for the new design-system ("dx-" components)
 */
export class BreakpointAware extends InternalBreakpointAware {
    /**
     * Constructs a new BreakpointAware object.
     * @param onSizeChange A callback function which is called as soon as the viewport hits a new breakpoint.
     */
    constructor(onSizeChange) {
        super(onSizeChange, true);
    }
    /**
     * Returns a number array containing all breakpoint widths.
     *
     * @returns An array of numbers.
     */
    static getBreakpoints() {
        return NEW_BREAKPOINTS;
    }
    /**
     * Returns one of the values defined in the mediaQueryValues parameter dependent on the current breakpoint.
     *
     * @param mediaQueryValues Contains a value for each breakpoint. (mq1, mq2, ...)
     */
    static getEffectiveValue(mediaQueryValues) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const currentBreakpointIndex = getBreakpointIndex(window.innerWidth, BreakpointAware.getBreakpoints());
        switch (currentBreakpointIndex) {
            case 0:
                return mediaQueryValues.mq1;
            case 1:
                return (_a = mediaQueryValues.mq2) !== null && _a !== void 0 ? _a : mediaQueryValues.mq1;
            case 2:
                return (_c = (_b = mediaQueryValues.mq3) !== null && _b !== void 0 ? _b : mediaQueryValues.mq2) !== null && _c !== void 0 ? _c : mediaQueryValues.mq1;
            case 3:
                return ((_f = (_e = (_d = mediaQueryValues.mq4) !== null && _d !== void 0 ? _d : mediaQueryValues.mq3) !== null && _e !== void 0 ? _e : mediaQueryValues.mq2) !== null && _f !== void 0 ? _f : mediaQueryValues.mq1);
            case 4:
                return ((_k = (_j = (_h = (_g = mediaQueryValues.mq5) !== null && _g !== void 0 ? _g : mediaQueryValues.mq4) !== null && _h !== void 0 ? _h : mediaQueryValues.mq3) !== null && _j !== void 0 ? _j : mediaQueryValues.mq2) !== null && _k !== void 0 ? _k : mediaQueryValues.mq1);
        }
    }
}
